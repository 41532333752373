<template>
  <div class="wrap">
    <div class="main" v-if="item">
      <div class="box nopadding">
        <div class="thumb">
          <img :src="item.goods_main_img" class="thumb-img" alt="">
        </div>
        <div class="info">
          <div class="title">
            <span class="text">{{ item.goods_name }}</span>
            <span class="tip">{{ item.guige }}</span>
          </div>
          <div class="desc">
            <div class="labels">
              <div class="label">
                <img src="@/assets/img/zzjf.png" alt="" class="icon">
                <span>{{ item.shoujia }} x {{ $route.query.num }}</span>
              </div>
            </div>
            <div class="btn">
              <VanButton type="primary" size="small">合计: {{ item.shoujia * $route.query.num }}</VanButton>
            </div>
          </div>
        </div>
      </div>
      <div class="box" v-if="user_detail">
        <div class="address">
          <div class="name">
            <span class="tag">自提门店</span>
            <span>{{ user_detail.ztmd?.store_name }}</span>
          </div>
          <div class="detail">{{ user_detail.ztmd?.mddz }}</div>
        </div>
        <!-- <div class="btns">
          <VanButton type="primary" size="small" plain @click="editAddressVisible = true;">修改提货点</VanButton>
        </div> -->
      </div>
    </div>
    <div class="footer">
      <VanButton type="primary" block @click="handleClickConfirm">确认兑换</VanButton>
    </div>
    <van-action-sheet v-model:show="editAddressVisible">
      <div style="max-height:400px;overflow:auto;">
        <VanCellGroup inset :border="false">
          <VanCell title="南湖大道社区店" label="南湖区南湖大道145号城新小区东门"></VanCell>
          <VanCell title="凤凰社区店" label="南湖区南湖大道145号城新小区东门"></VanCell>
          <VanCell title="优优花园店" label="南湖区南湖大道145号城新小区东门"></VanCell>
          <VanCell title="南湖大道社区店" label="南湖区南湖大道145号城新小区东门"></VanCell>
          <VanCell title="凤凰社区店" label="南湖区南湖大道145号城新小区东门"></VanCell>
          <VanCell title="优优花园店" label="南湖区南湖大道145号城新小区东门"></VanCell>
          <VanCell title="南湖大道社区店" label="南湖区南湖大道145号城新小区东门"></VanCell>
          <VanCell title="凤凰社区店" label="南湖区南湖大道145号城新小区东门"></VanCell>
          <VanCell title="优优花园店" label="南湖区南湖大道145号城新小区东门"></VanCell>
          <VanCell title="南湖大道社区店" label="南湖区南湖大道145号城新小区东门"></VanCell>
          <VanCell title="凤凰社区店" label="南湖区南湖大道145号城新小区东门"></VanCell>
          <VanCell title="优优花园店" label="南湖区南湖大道145号城新小区东门"></VanCell>
          <VanCell title="南湖大道社区店" label="南湖区南湖大道145号城新小区东门"></VanCell>
          <VanCell title="凤凰社区店" label="南湖区南湖大道145号城新小区东门"></VanCell>
          <VanCell title="优优花园店" label="南湖区南湖大道145号城新小区东门"></VanCell>
          <VanCell title="南湖大道社区店" label="南湖区南湖大道145号城新小区东门"></VanCell>
          <VanCell title="凤凰社区店" label="南湖区南湖大道145号城新小区东门"></VanCell>
          <VanCell title="优优花园店" label="南湖区南湖大道145号城新小区东门"></VanCell>
        </VanCellGroup>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
// @ is an alias to /src

import axios from 'axios';
import { showConfirmDialog, showNotify } from 'vant';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Fwtc',
  data() {
    return {
      editAddressVisible: false,
      active: 0,
      item: {},
      prePayNum: 1,
    }
  },
  created() {
    this.getData();
    this.get_user_detail({
      sjhm: this.$route.query.sjhm
    });
  },
  mounted() {

  },
  computed: {
    ...mapState(['user_detail']),
  },
  methods: {
    ...mapActions(['get_user_detail']),
    async getData() {
      let { data: res } = await axios.get('/api/api/get_goods_data', {
        params: {
          sjhm: this.$route.query.sjhm
        }
      });
      if (res?.success) {
        // res.data = res.data.map(m => ({ ...m, prePayNum: 1 }))
        // this.list = res.data;
        this.item = res.data.find(f => f.id == this.$route.query.id) || {}
      }
    },
    handleClickConfirm() {
      showConfirmDialog({
        title: '提示',
        message: `是否确认购买[${this.item.goods_name}]商品?`,
        confirmButtonText: '确定'
      }).then(async res => {
        // console.log(res);
        let { data: result } = await axios.get('/api/api/goods_order', {
          params: {
            sjhm: this.$route.query.sjhm,
            sfbs: this.$route.query?.sfbs || '',
            goods_id: this.item.id,
            num: this.$route.query.num
          }
        })
        if (result?.success) {
          showNotify({
            type: 'success',
            message: result.msg || '购买成功'
          })
          setTimeout(() => {
            this.$router.back();
          }, 1000)
        } else {
          showNotify({
            type: 'danger',
            message: result.msg || '网络错误'
          })
        }
      })
    }
  },

}
</script>
<style lang="less" scoped>
.wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .main {
    flex: 1;
    width: 100%;
    padding-top: 12px;

    .box {
      margin: 12px;
      padding: 12px;
      background: #fff;
      border-radius: 10px;
      overflow: hidden;

      &.nopadding {
        padding: 0;

        .info {
          padding: 0 12px 12px 12px;
        }
      }

      .thumb {
        overflow: hidden;
        margin-bottom: 12px;

        .thumb-img {
          vertical-align: top;
          width: 100%;
          height: auto;
        }
      }

      .box-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6px;

        .title {
          flex: 1;
        }

        .number {
          color: #3fb6c6;
          font-size: 16px;
        }
      }

      .address {
        .name {
          font-size: 20px;
          color: #333;
          margin-bottom: 6px;
          line-height: 32px;

          .tag {
            display: inline-block;
            padding: 0 12px;
            height: 32px;
            line-height: 32px;
            border-radius: 3px;
            background: #3fb6c6;
            color: #fff;
            margin-right: 12px;
            font-size: 16px;
          }
        }

        .detail {
          font-size: 16px;
          color: #666;
        }
      }

      .title {
        font-size: 20px;
        color: #333;

        .tip {
          font-size: 14px;
          color: #999;
          margin-left: 12px;
        }
      }

      .btns {
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
      }

      .desc {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .labels {
          display: flex;
          align-items: center;
          flex: 1;

          .label {
            display: flex;
            align-items: flex-end;
            margin-right: 24px;

            .icon {
              width: 24px;
              height: 24px;
              margin-right: 6px;
            }

            span {
              font-size: 18px;
              color: #3fb6c6;
            }
          }
        }

        .btn {
          margin-left: 12px;
        }

      }
    }
  }

  .footer {
    width: calc(100% - 24px);
    margin: 12px
  }
}
</style>
